import React, { useState } from 'react'
import { close, logo, menu } from '../assets'
import { styles } from '../styles'
import { navigation } from '../constants'
import { Link } from 'react-router-dom'

const Header = () => {
  const [active, setActive] = useState('');
  const [toggle, setToggle] = useState(false);

  return (
    <div className= {`${styles.paddingX} w-full flex top-0 z-20 fixed bg-primary py-5 items-center`}>
      <div className='w-full flex items-center justify-between max-w-7xl mx-auto'>
        <Link to="/" className='block w-[11rem] md:mr-5 xl:mr-[20.125rem] cursor-pointer' onClick={() => {setActive(''); window.scrollTo(0,0);}}>
          <img src={logo} alt="logo" width={180} height={54} className='object-contain' />
        </Link>

        <ul className='list-none hidden sm:flex flex-row gap-5 xl:gap-10'>
          {navigation.map((link) => (
            <li 
            key={link.id}
            className={`${active === link.title ? "text-secondary" : "text-white/80"} font-semibold font-poppins uppercase text-sm hover:text-white cursor-pointer transition-colors 
            ${link.onlyMobile ? "lg:hidden" : " " }`}
            onClick={() => (setActive(link.title))}
            >
              <a href={link.url}>{link.title}</a>
            </li>
          ))}
        </ul>

        <a 
        className='hidden lg:block ml-[5.625rem] border border-secondary text-white font-bold text-sm uppercase px-5 py-2.5 hover:text-secondary transition-colors rounded-md' 
        href='#contactez-nous'>
          Contactez-nous
        </a>

        {/** Mobile Navbar */}
        <div className='sm:hidden flex flex-1 justify-end items-center'>
          <img src={!toggle ? menu : close} alt='menu' 
          className='w-[28px] h-[28px] object-contain cursor-pointer'
          onClick={() => setToggle(!toggle)}
          />

          <div className={`${!toggle ? 'hidden' : 'flex'} p-6 bg-primary absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}>
            <ul className='list-none sm:flex flex-col justify-end items-start'>
              {navigation.map((link, index) => (
                <li 
                key={link.id}
                className={`${active === link.title ? "text-secondary" : "text-white/90" } ${index !== navigation.length-1 ? "mb-4" : "mb-0"} uppercase font-medium`}
                onClick={() => {setToggle(!toggle); setActive(link.title);}}
                >
                  <a href={link.url}>{link.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Header;