import React, { useState } from 'react'
import { contactInfos } from '../constants'
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { fadeIn, staggerContainer } from '../utils/motion';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';

///template_jdd3tnf
//service_4l7hdf3
//AbUg960zPr81cA-jM

const Contact = () => {

  const formRef = useRef();

  const [form, setForm] = useState({
    name: '',
    contact: '',
    email: '',
    title: '',
    message: ''
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.send(
      'service_wvw4gwb', 
      'template_fqgkf8a',
      {
        from_name: form.name,
        to_name: 'MBWAN Consulting',
        from_email: form.email,
        to_email: 'contacts@mbwan.com',
        message: form.message,
        reply_to: form.email,
        from_contact: form.contact,
      },
      'GescS6qu2Nn3uB5BK'
    ).then(() => {
      setLoading(false);
      toast.success('Votre message a été envoyé ! Nous reviendrons vers vous très bientôt.', {
        duration: 5000,
        style: {
          border: '1px solid #003366',
          padding: '16px',
          color: '#003366',
        },
        iconTheme: {
          primary: '#003366',
          secondary: '#FFFAEE',
        },
      });

      setForm({
        name: '',
        contact: '',
        email: '',
        title: '',
        message: '',
      });
    }, (error) => {
      setLoading(false);
      
      console.log(error);

      toast.error("Un Erreur s'est produit!");
    })
  }

  const handleChange = (e) => {
    const { name, value} = e.target;

    setForm({...form, [name]: value});
  }

  return (
    <motion.section
    variants={staggerContainer()}
    initial="hidden"
    whileInView="show"
    viewport={{ once: true, amount: 0.25}} 
    id='contactez-nous' 
    className='relative z-0 mt-20 max-w-7xl mx-auto flex flex-col lg:flex-row items-center px-4 lg:px-0'
    >
      <motion.div variants={fadeIn('left', "tween", 0.2, 1)} className='flex flex-col w-full'>
        <div className='w-full flex flex-col space-y-4'>
          <span className='text-secondary uppercase font-medium text-base'>contactez-nous</span>

          <h1 className='font-black text-2xl lg:text-3xl leading-[150%] text-textColor'>
            N'hésitez Pas à Nous Contacter pour des Informations et Services Optimaux
          </h1>

          <p className='text-sm text-textColor'>
            Nous sommes là pour répondre à toutes vos questions et vous fournir des informations détaillées sur nos services. 
            Ne laissez aucune hésitation vous retenir. 
            Contactez-nous dès maintenant pour découvrir comment nous pouvons optimiser vos solutions informatiques et répondre à vos besoins spécifiques.
          </p>
        </div>

        <div className='flex flex-col py-2.5 mt-7 space-y-[50px]'>
          {contactInfos.map((info, index) => (
            <div key={index} className='flex flex-row items-center'>
              <div>
                <img src={info.iconUrl} alt={info.title} width={42} height={42} />
              </div>

              <div className='flex flex-col ml-6'>
                <p className='text-textColor text-base font-semibold'>{info.title}</p>
                <p className='mt-1 text-textColor text-sm'><span className='font-medium'>{info.subtitle}</span>{info.info}</p>
              </div>
            </div>
          ))}
        </div>
      </motion.div>

      <motion.form variants={fadeIn('right', "tween", 0.2, 1)}
      ref={formRef}
      onSubmit={handleSubmit}
      className='w-full flex flex-col gap-8 lg:ml-12'>
        <input 
        type='text'
        name='name'
        value={form.value}
        onChange={handleChange}
        placeholder='Votre Nom'
        className='py-4 px-4 lg:px-6 text-textColor border border-solid border-secondary border-opacity-50 outline-secondary rounded-md text-sm' required/>

        <div className='flex flex-row space-x-3'>
          <input 
          type='text'
          name='contact'
          value={form.contact}
          onChange={handleChange}
          placeholder='Votre Contact'
          className='w-full py-4 px-4 lg:px-6 text-textColor border border-solid border-secondary border-opacity-50 outline-secondary rounded-md text-sm' required/>

          <input 
          type='email'
          name='email'
          value={form.email}
          onChange={handleChange}
          placeholder='Votre E-mail'
          className='w-full py-4 px-4 lg:px-6 text-textColor border border-solid border-secondary border-opacity-50 outline-secondary rounded-md text-sm' required/>
        </div>

        <input 
        type='text'
        name='title'
        value={form.title}
        onChange={handleChange}
        placeholder="L'objet de Votre Message"
        className='py-4 px-4 lg:px-6 text-textColor border border-solid border-secondary border-opacity-50 outline-secondary rounded-md text-sm' required/>

        <textarea
        rows={7}
        name='message'
        value={form.message}
        onChange={handleChange}
        placeholder='Votre Message'
        className='py-4 px-6 text-textColor border border-solid border-secondary border-opacity-50 outline-secondary rounded-md text-sm' required/>

        <button type='submit' className='py-3 px-8 w-full font-semibold text-white 
        bg-secondary rounded-md hover:bg-transparent hover:border hover:border-secondary hover:text-secondary' >
          { loading ? " EN COURS D'ENVOI ..." : " ENVOYER " }
        </button>
      </motion.form>
    </motion.section>
  )
}

export default Contact