import React from 'react'
import { projects } from '../constants';
import { motion } from 'framer-motion';
import { fadeIn, staggerContainer } from '../utils/motion';

const Projects = () => {
  return (
    <motion.section
    variants={staggerContainer()}
    initial="hidden"
    whileInView="show"
    viewport={{ once: true, amount: 0.25}}
    id='nos-realisations' 
    className='relative z-0 mx-auto max-w-7xl mt-14 lg:mt-28'
    >
      <div className='flex flex-col text-center'>
        <span className='text-secondary uppercase text-center text-[16px] font-medium'>innovations impactantes</span>

        <h1 className='text-textColor font-black text-[34px] lg:text-[40px] leading-[140%]'>
          Découvrez nos Réalisations Récentes
        </h1>

        <div className='flex text-center mx-4 mt-3 lg:mx-64'>
          <p className='text-textColor text-sm'>
            Explorez nos projets les plus récents et découvrez l'impact significatif qu'ils ont eu sur les PME que nous accompagnons. 
            Chaque initiative, conçue sur mesure, répond aux besoins spécifiques de nos clients, propulsant leur croissance à travers des solutions informatiques novatrices.
          </p>
        </div>
        
      </div>

      <div className='mt-8 py-2.5 flex flex-wrap gap-5 mx-4 lg:mx-0'>
        {projects.map((project, index) => (
          <motion.div 
          variants={fadeIn("up", "spring", index * 0.5, 0.75)}
          key={index}
          >
            <div
            options={{
              max: 45,
              scale: 1,
              speed: 450
            }} 
            id={index} 
            className='w-full lg:w-[300px] shadow-card hover:shadow-2xl rounded-md'
            >
              <div className='h-[200px] overflow-hidden hidden lg:block'>
                <img src={project.imageUrl} alt={project.title} className='object-cover rounded-t-md w-full h-full hover:scale-105 transition-scale duration-200 ' loading='lazy' />
              </div>

              <div className='w-full flex flex-row bg-primary py-2.5 items-center'>
                <div className='ml-[5px]'>
                  <img src={project.iconUrl} alt='' className='h-[45px]' width={60} height={45}  />
                </div>

                <div className='flex flex-col ml-2.5'>
                  <p className='text-white font-semibold text-[15px]'>{project.title}</p>
                  <span className='text-secondary text-[13px]'>{project.service}</span>
                </div>
              </div>

              <div className='w-full py-2.5 px-[5px]'>
                <p className='text-textColor text-[13px]'>{project.text}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  )
}

export default Projects