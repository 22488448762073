import React from 'react'
import { testimonials } from '../constants'
import { client, quote_icon, stars } from '../assets'

const Testimonial = () => {
  return (
    <section className='relative z-0 mt-14 lg:mt-20 bg-primary bg-opacity-95'>
      <div className='mx-auto max-w-7xl px-4 lg:px-0'>
        <div className='flex flex-col w-full'>
          <span className='text-secondary uppercase mt-6 font-medium text-center'>Témoignages</span>

          <h1 className='text-white text-center mt-3 font-black text-[34px] lg:text-[40px] leading-[140%] tracking-wide'>
            Ce Que Nos Clients <span className='text-secondary'>Disent</span> de Nous
          </h1>

          <div className='flex flex-wrap w-full lg:p-2.5 mt-14 mb-11 gap-[50px] lg:justify-center justify-start'>
            {testimonials.map((testimonial, index) => (
              <div id={index} key={index} className='relative w-full lg:w-[360px] px-[20px] lg:px-[30px] py-[20px] border border-white border-solid border-opacity-60 rounded-lg hover:border-secondary'>
                <img src={stars} alt='rating' width={154} height={30} />
                <img src={quote_icon} alt='quote' className='absolute top-3 right-7' width={60} height={45} />

                <p className='text-white w-[300px] text-sm leading-[150%] mt-6 lg:mt-12'>
                  {testimonial.message}
                </p>

                <div className='flex flex-row w-full items-center mt-6 lg:mt-12'>
                  <div>
                    <img src={client} alt={testimonial.name} width={80} height={80} />
                  </div>

                  <div className='flex flex-col ml-5'>
                    <p className='text-white mb-1 text-base font-semibold leading-[150%]'>{testimonial.name}</p>
                    <p className='text-white text-sm leading-[150%]'>{testimonial.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonial