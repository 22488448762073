import React from 'react'

const Achievements = () => {
  return (
    <section className='relative z-0 mx-auto max-w-7xl -mt-5 lg:-mt-20'>
      <div className='justify-center px-5 py-10 rounded-[10px] shadow-card bg-linear-gradient mx-10 lg:mx-[160px]'>
        <div className='flex flex-col lg:flex-row gap-5 lg:gap-[50px]'>
          <div className='flex flex-col text-center'>
            <h1 className='text-white font-bold text-5xl leading-[140%]'>20+</h1>
            <p className='mt-2 text-white lg:text-[16px] leading-[150%]'>Clients Satisfaits</p>
          </div>

          <div className='w-px h-[120px] bg-white flex-grow-0 hidden lg:block'></div>
          <div className='h-px w-[220px] bg-white flex lg:hidden justify-center ml-8'></div>

          <div className='flex flex-col text-center'>
            <h1 className='text-white font-bold text-5xl leading-[140%]'>10+</h1>
            <p className='mt-2 text-white lg:text-[16px] leading-[150%]'>Projets Réalisés</p>
          </div>

          <div className='w-px h-[120px] bg-white flex-grow-0 hidden lg:block'></div>
          <div className='h-px w-[220px] bg-white flex lg:hidden justify-center ml-8'></div>

          <div className='flex flex-col text-center'>
            <h1 className='text-white font-bold text-5xl leading-[140%]'>15+</h1>
            <p className='mt-2 text-white lg:text-[16px] leading-[150%]'>Années d’Expériences</p>
          </div>

          
          <div className='w-px h-[120px] bg-white flex-grow-0 hidden lg:block'></div>
          <div className='h-px w-[220px] bg-white flex lg:hidden justify-center ml-8'></div>

          <div className='flex flex-col text-center'>
            <h1 className='text-white font-bold text-5xl leading-[140%]'>10+</h1>
            <p className='mt-2 text-white lg:text-[16px] leading-[150%]'>Partenariats Solides</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Achievements