import React from 'react'
import { About, Contact, Features, Footer, Header, Hero, Projects, Services, Testimonial } from '../components'
import Achievements from '../components/Achievements'
import SocialProof from '../components/SocialProof'
import { Toaster } from 'react-hot-toast'

export default function Home() {
  return (
    <>
      <div className='relative z-0'>
        <Toaster
          position='top-center'
          reverseOrder={true} 
        />
        <Header />
        <Hero />
        <Features />
        <Services />
        <Achievements />
        <About />
        <Projects />
        <Testimonial />
        <SocialProof />
        <Contact />
        <div className='mt-10 h-px border border-[#cbcbcb]'></div>
        <Footer />
      </div>
    </>
  )
}
