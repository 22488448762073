import React, { useState } from 'react'
import { features } from '../constants'
import { debounce } from 'lodash';
import { motion } from 'framer-motion';
import { fadeIn, staggerContainer } from '../utils/motion';

const Features = () => {
  const [hoverStates, setHoverStates] = useState(new Array(features.length).fill(false));

  const debouncedSetHoverStates = debounce((index, value) => {
    setHoverStates(prevStates => {
      const newHoverStates = [...prevStates];
      newHoverStates[index] = value;
      return newHoverStates;
    });
  }, 1); //Adjust de debounce delay as needed

  const handleMouseEnter = (index) => {
    debouncedSetHoverStates(index, true);
  }

  const handleMouseLeave = (index) => {
    debouncedSetHoverStates(index, false);
  }

  return (
    <motion.section
    variants={staggerContainer()}
    initial="hidden"
     whileInView="show"
     viewport={{ once:true, amount:0.25 }}
    className='relative z-0 py-2.5 max-w-7xl mx-auto -mt-10 lg:-mt-16'
    >
      <div className='flex flex-wrap gap-10 px-4 lg:px-0'>
        {features.map((feature, index) => (
          <motion.div
          variants={fadeIn("right", "spring", 0.5 * index, 0.75)}
          key={index} 
          >
            <div
            options={{
              max: 45,
              scale: 1,
              speed: 450
            }} 
            className='bg-white text-textColor px-[15px] py-[12px] flex flex-col w-full md:w-[290px] shadow-card hover:bg-secondary hover:text-white transition-colors rounded-lg'
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
            >
              <div className='mb-5'>
                <img src={!hoverStates[index] ? feature.iconUrl : feature.iconUrlWhite} alt={feature.title} width={60} height={60} className='' />
              </div>

              <p className='mb-5 font-semibold text-lg '>{feature.title}</p>

              <p className='text-[13px] leading-[150%] lg:w-[242px]'>{feature.text}</p>
            </div>
          </motion.div>
          
        ))}
      </div>
    </motion.section>
  )
}

export default Features