import React from 'react'

const Footer = () => {
  return (
    <section className='relative z-0 mx-auto max-w-7xl justify-center mt-5 mb-2'>
      <p className='text-center text-[15px] text-primary'>Copyright©{new Date().getFullYear()} MBWAN Consulting. Tous droits réservés.</p>
      <p className='mt-4 text-center text-sm'>Powered by Aurora</p>
    </section>
  )
}

export default Footer