import React from 'react'

const Hero = () => {
  return (
    <section
    id='accueil' 
    className='relative mt-[90px] overflow-hidden bg-cover bg-no-repeat bg-hero h-[752px] object-contain bg-center mx-auto' 
    >
      <div className='absolute inset-0 h-full w-full overflow-hidden bg-fixed bg-bgOverlay'>
        <div className={`max-w-7xl mx-auto flex flex-col lg:flex-row items-start gap-5`}>
          <div className='flex flex-col mt-16 w-full px-4 md:px-0'>
            <p className='py-2.5 text-white uppercase lg:text-[16px] text-sm font-poppins leading-normal tracking-wide text-center'>
              Débloquez la Croissance avec nos Solutions Informatiques
            </p>
            
            <h1 className='mt-2.5 text-white font-black text-[42px] lg:text-[72px] leading-[150%] tracking-normal text-center'>
              Solutions <br/><span className='text-secondary'>Informatiques</span><br/> Stratégiques pour PME
            </h1>

            <p className='mt-5 text-white text-[16px] lg:text-lg w-full leading-[150%] tracking-wide text-center'>
              Débloquez la croissance de votre PME avec nos solutions informatiques stratégiques.<br/> 
              Optimisez l'efficacité opérationnelle, renforcez la sécurité numérique, et préparez votre entreprise pour un avenir numérique florissant.
            </p>

            <div className='mt-10 text-center'>
              <a 
              className='text-white uppercase lg:text-lg text-[16px] font-semibold 
              tracking-wider bg-[#b85c2d] w-[250px] lg:w-[350px] px-5 py-[16px] hover:bg-transparent 
              hover:border hover:border-secondary hover:text-secondary transition duration-100 rounded-md' 
              href='#contactez-nous'>
                Contactez notre équipe
              </a>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero