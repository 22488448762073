import React from 'react'
import { companyLogos } from '../constants'

const SocialProof = () => {
  return (
    <section className='relative z-0 bg-[#fff5f0] py-3 lg:py-8'>
        <div className='flex flex-row max-w-7xl mx-auto gap-1 lg:gap-[60px] items-center px-4 lg:px-0 justify-center'>
            {companyLogos.map((logo, index) => (
                <img src={logo} key={index} id={index} alt='Company Logo' className='w-16 lg:w-full h-16 lg:h-full' />
            ))}
        </div>
    </section>
  )
}

export default SocialProof