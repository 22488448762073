import React from 'react'
import { ethernet, quotation_icon, tick_icon } from '../assets'
import { values } from '../constants';
import { motion } from 'framer-motion';
import { slideIn, staggerContainer } from '../utils/motion';

const About = () => {
  return (
    <motion.section
    variants={staggerContainer()}
    initial="hidden"
    whileInView="show"
    viewport={{ once: true, amount: 0.25}}
    id='a-propos' 
    className='z-0 relative max-w-7xl mx-auto mt-10 lg:mt-20 justify-center'
    >
      <div className='flex flex-col-reverse lg:flex-row lg:space-x-12 lg:mx-36 w-full'>
        <motion.div variants={slideIn('left', "tween", 0.2, 1)} className='relative z-0 w-[350px] h-[600px] mx-auto lg:mx-0 mt-8 lg:mt-0 lg:w-[444px] lg:h-[647px]'>
          <img src={ethernet} alt='ethernet' className='object-cover w-full h-full' loading='lazy' />
          <div className='absolute inset-0 bg-bgOverlay3 bg-fixed overflow-hidden'></div>
          <div className='absolute right-0 bottom-0 p-5 bg-primary flex flex-col w-[250px] lg:w-[268px]'>
            <p className='text-white text-sm font-medium italic'>
              Fondée sur une vision solide et un soutien inébranlable, notre entreprise est guidée par la passion de créer un impact positif. 
              Nous sommes fiers de notre parcours et résolus à façonner l'avenir de l'informatique d'entreprise.
            </p>
            <p className='mt-6 text-white font-light text-[12px]'>
              Max  - CEO MBWAN Consulting
            </p>
          </div>
          <div className='absolute right-5 bottom-56'>
            <img src={quotation_icon} alt='quotation' width={67} height={50}/>
          </div>
          <div className='absolute bg-primary w-[100px] h-[100px] lg:w-[165px] lg:h-[165px] rounded-full -left-10 -bottom-10 lg:-left-16 lg:-bottom-16 -z-10'></div>
        </motion.div>

        <motion.div variants={slideIn('right', "tween", 0.2, 1)} className='flex flex-col w-full lg:w-[601px]'>
          <div className='flex flex-col p-2.5 gap-y-2.5 text-center lg:text-left'>
            <span className='text-secondary font-medium text-[16px] leading-[150%] uppercase'>Qui sommes-nous ?</span>
            <h1 className='font-black text-textColor text-4xl lg:text-[40px] w-full lg:w-[558px]'>Des Pionniers de l'Innovation Informatique</h1>
            <p className='text-textColor text-sm leading-[150%] w-full'>
              Nous sommes une équipe passionnée dédiée à repousser les limites de l'innovation informatique. 
              Notre engagement envers l'excellence se reflète dans chaque solution que nous offrons, créant des expériences numériques uniques pour nos clients.
            </p>
          </div>

          <div className='flex flex-col mt-6 w-full lg:w-[570px] p-2.5 gap-y-6'>
            {values.map((value, index) => (
              <div id={index} key={index} className='flex flex-row w-full'>
                <div className='mr-2'>
                  <img src={tick_icon} alt='tick icon' width={25} height={25} />
                </div>

                <div className='flex flex-col w-full gap-y-3'>
                  <h2 className='font-semibold text-textColor text-[15px] leading-[150%]'>{value.title}</h2>
                  <p className='text-textColor text-[13px] leading-[150%]'>{value.text}</p>
                </div>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.section>
  )
}

export default About