import React from 'react'
import { services } from '../constants'
import SectionWrapper from '../hoc/SectionWrapper';
import { motion } from 'framer-motion';
import { fadeIn, textVariant } from '../utils/motion';

const className = "relative z-0 mt-[80px] bg-cover bg-no-repeat bg-map object-contain bg-center h-[1400px] lg:h-[62vh] overflow-auto";
const id = "nos-services";

const Services = () => {
  
  return (
    <>
      <div className='absolute inset-0 w-full h-full bg-bgOverlay2 bg-fixed overflow-hidden'>
        <div className='max-w-7xl mx-auto flex flex-col'>
          <div className='w-full flex flex-col lg:flex-row mt-10 items-center gap-[30px] lg:gap-[70px] px-4 lg:px-0'>
            <motion.div variants={textVariant()} className='flex flex-col w-full lg:w-[520px] items-center lg:items-start'>
              <p className='text-white uppercase font-medium tracking-wide text-sm'>Nos services</p>
              <h1 className='text-white font-black text-[30px] lg:text-[40px] leading-[150%] text-center lg:text-left'>Notre Palette de Services</h1>
            </motion.div>

            <div className='flex w-full lg:w-[650px] items-center lg:items-start'>
              <motion.p variants={fadeIn("", "", 0.1, 1)} className='text-white text-[15px] leading-[150%] text-center lg:text-left'>
                Découvrez notre gamme complète de services conçus pour <span className='text-secondary'>catalyser la croissance des PME</span>. 
                De la sécurité informatique à l'intégration systémique, nos solutions personnalisées visent à renforcer et à optimiser votre infrastructure numérique.
              </motion.p>
            </div>
          </div>

          <div className='w-full items-start py-2.5 mt-10 px-5 lg:px-0'>
            <div className='w-full flex flex-wrap gap-[40px]'>
              {services.map((service, index) => (
                <motion.div 
                key={index}
                variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
                  <div 
                  options={{
                    max: 45,
                    scale: 1,
                    speed: 450
                  }}
                  id={index} 
                  className='p-5 border border-solid border-white border-opacity-40 rounded-lg w-full lg:w-[290px] hover:border-secondary'>
                    <div className='mb-2.5'>
                      <img src={service.iconUrl} alt={service.title} width={50} height={50} />
                    </div>

                    <div>
                      <p className='text-white text-opacity-80 text-[16px] leading-[150%] font-semibold'>{service.title}</p>
                    </div>

                    <div className='w-full mt-2.5'>
                      <p className='text-white text-opacity-80 text-[13px] leading-[150%] self-start text-left'>{service.text}</p>
                    </div>
                  </div>
                </motion.div>
                
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionWrapper(Services, id, className)