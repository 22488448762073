import React from 'react'
import { motion } from 'framer-motion'
import { staggerContainer } from '../utils/motion'

const SectionWrapper = (Component, idName, className) => function HOC() {
  return (
    <motion.section
        variants={staggerContainer()}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25}} 
        className={`${className}`}>
        <span className='hash-span' id={idName}>
            &nbsp;
        </span>
        <Component />
    </motion.section>
  )
}

export default SectionWrapper