import { 
    RentACar_logo, 
    address_icon, 
    alcatel_logo, 
    audience_icon, 
    audience_icon2, 
    cloud_data_icon, 
    cloud_migration, 
    contact_icon, 
    digital, 
    email_icon, 
    globe_icon, 
    hubOne_logo, 
    it_icon, 
    lamp_icon, 
    lamp_icon2, 
    lvmh_logo, 
    oddoBHF_logo, 
    presentation_icon, 
    router_icon, 
    security, 
    service_icon, 
    service_icon2, 
    shield_icon, 
    solution_icon, 
    solution_icon2, 
    training, 
    training_icon, 
    work_icon } from "../assets";

export const navigation = [
    {
        id: "0",
        title: "Accueil",
        url: "#accueil"
    },
    {
        id: "1",
        title: "Nos Services",
        url: "#nos-services",
    },
    {
        id: "2",
        title: "A Propos",
        url: "#a-propos",
    },
    {
        id: "3",
        title: "Nos Réalisations",
        url: "#nos-realisations"
    },
    {
        id: "4",
        title: "Contactez-nous",
        url: "#contactez-nous",
        onlyMobile: true,
    },
];

export const features = [
    {
        id: "0",
        title: "Support 24/7",
        text: "Bénéficiez d'un support technique 24/7 dédié à votre service. Notre équipe d'experts est toujours prête à résoudre vos problèmes, assurant une continuité opérationnelle sans interruption, peu importe le moment de la journée.",
        iconUrl: service_icon,
        iconUrlWhite: service_icon2,
    },
    {
        id: "1",
        title: "Certification Industrielle",
        text: "Profitez de solutions informatiques certifiées par l'industrie. Notre engagement envers l'excellence est renforcé par des certifications reconnues, garantissant des services conformes aux normes les plus élevées de l'industrie IT.",
        iconUrl: solution_icon,
        iconUrlWhite: solution_icon2
    },
    {
        id: "2",
        title: "Solutions Personnalisées",
        text: "Découvrez des solutions informatiques adaptées à vos besoins. Nous concevons des solutions pour répondre aux exigences de votre entreprise, assurant une performance optimale et une efficacité maximale.",
        iconUrl: audience_icon,
        iconUrlWhite: audience_icon2,
    },
    {
        id: "3",
        title: "Innovation Continue",
        text: "Restez à la pointe de la technologie avec notre engagement envers l'innovation continue. Nous vous apportons les dernières avancées technologiques pour garantir que vos systèmes informatiques évoluent constamment.",
        iconUrl: lamp_icon,
        iconUrlWhite: lamp_icon2,
    },
];

export const services = [
    {
        id: "0",
        title: "Internet & Sécurité",
        text: "Besoin de conseils en solutions réseau et sécurité? SD-WAN, Cloud, WAN, Zscaler, Firewall... Notre expertise assure une recommandation adaptée à vos exigences spécifiques.",
        iconUrl: globe_icon,
    },
    {
        id: "1",
        title: "Intégrations & Maintenance",
        text: "Nous intégrons les technologies de routage, firewalling, proxy, et autre. Nous restons disponible pour la maintenance de ces derniers par nos experts.",
        iconUrl: work_icon,
    },
    {
        id: "2",
        title: "Formations IT",
        text: "Nous formons les techniciens et ingénieurs sur les solutions les plus connues; CISCO, MERAKI, FORTIGATE, PALO ALTO, Zscaler, ... et sur des parcours de certifications.",
        iconUrl: training_icon,
    },
    {
        id: "3",
        title: "Vente Matériels Informatique",
        text: "Nous vendons des matériels aux entreprises, dans une démarche écoresponsable. Ces équipements sont sous garantie matérielle de plusieurs mois en fonction du modèle.",
        iconUrl: router_icon,
    },
];

export const values = [
    {
        id: "0",
        title: "Engagement Client",
        text: "Notre premier principe est de placer nos clients au cœur de tout ce que nous faisons. Leur satisfaction est notre priorité, guidant chaque décision et action de notre équipe.",
    },
    {
        id: "1",
        title: "Intégrité Technologique",
        text: "Nous adhérons à une intégrité technologique sans compromis. Chacune de nos solutions est construite sur des fondations solides, garantissant des performances fiables et une sécurité optimale.",
    },
    {
        id: "2",
        title: "Innovation Continue",
        text: "Nous embrassons l'innovation continue. Cela signifie rester à la pointe des technologies émergentes, afin d'offrir à nos clients des solutions qui anticipent et dépassent leurs besoins.",
    },
];

export const projects = [
    {
        id: "0",
        title: "Transformation Numérique",
        service: "Intégrations & Maintenance",
        text: "Nous avons mené une transformation numérique complète pour une PME, intégrant des systèmes de gestion de la relation client (CRM) et de gestion des ressources humaines (HRM), optimisant ainsi leurs opérations et améliorant leur productivité.",
        imageUrl: digital,
        iconUrl: it_icon,
    },
    {
        id: "1",
        title: "Renforcement Sécurité",
        service: "Internet & Sécurité",
        text: "En renforçant les mesures et politiques de sécurité informatique d'une entreprise, nous avons réduit les risques de cyber menaces, cyberattaques et protégé leurs données sensibles, assurant ainsi une continuité opérationnelle sans faille et l’intégrité des données à travers les applications métiers.",
        imageUrl: security,
        iconUrl: shield_icon,
    },
    {
        id: "2",
        title: "Migrations vers le Cloud",
        service: "Internet & Sécurité",
        text: "Nous avons planifié et réalisé une migration transparente vers le cloud pour une PME, leur offrant une infrastructure évolutive et flexible, réduisant les coûts d'exploitation et augmentant leur agilité commerciale dans le but d’atteindre les objectifs visés et de sécuriser les revenus.",
        imageUrl: cloud_migration,
        iconUrl: cloud_data_icon,
    },
    {
        id: "3",
        title: "Formations Avancées",
        service: "Formations IT",
        text: "Offrant des formations sur mesure, nous avons renforcé les compétences de l'équipe de cette PME en technologies avancées. Résultat : une équipe plus compétente et prête à relever les défis numériques avec confiance. Ils ont également bénéficiés d’un accompagnement 24/7.",
        imageUrl: training,
        iconUrl: presentation_icon,
    },
];

export const testimonials = [
    {
        id: "0",
        name: "Marie",
        role: "Directrice des Opérations",
        message: "Les services exceptionnels de cette entreprise ont propulsé notre efficacité opérationnelle. Une équipe dévouée et des solutions innovantes. Nous sommes ravis de notre partenariat.",
    },
    {
        id: "1",
        name: "Julien",
        role: "Responsable Informatique",
        message: "Une migration vers le cloud sans heurts et une sécurité informatique renforcée. Leur expertise a considérablement amélioré notre infrastructure. Hautement recommandé.",
    },
    {
        id: "2",
        name: "Sarah",
        role: "Directrice Marketing",
        message: "Des formations personnalisées qui ont renforcé nos compétences numériques. Une équipe professionnelle et à l'écoute. Une expérience client exceptionnelle. Des résultats concrets.",
    },
];

export const companyLogos = [alcatel_logo, RentACar_logo, lvmh_logo, hubOne_logo, oddoBHF_logo];

export const contactInfos = [
    {
        id: "0",
        title: "Localisation",
        subtitle:"Notre Adresse: ",
        info: "75000 Paris, France",
        iconUrl: address_icon,
    },
    {
        id: "1",
        title: "Téléphone",
        subtitle:"Service Client: ",
        info: "+33 7 49 39 41 26",
        iconUrl: contact_icon,
    },
    {
        id: "2",
        title: "E-mail",
        subtitle:"Contactez-nous par e-mail: ",
        info: "contacts@mbwan.com",
        iconUrl: email_icon,
    },
];